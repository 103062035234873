<template>
  <div>
    <v-container class="mb-4">
      <v-row class="align-center justify-center">
        <v-btn-toggle v-model="view" dense mandatory>
          <v-btn text value="panel">Spiegel</v-btn>
          <v-btn text value="list">Liste</v-btn>
        </v-btn-toggle>

        <v-spacer></v-spacer>

        <EmailChip
          v-if="$_hasRole('staff')"
          class="mr-2"
          title="E-Mail an Lehrpersonen der Klasse"
          :value="teacherDL"
        ></EmailChip>
      </v-row>
    </v-container>
    <template v-if="view == 'panel'">
      <v-card class="mb-4">
        <v-system-bar>Klassenteam</v-system-bar>
        <v-card-text>
          <PeoplePanel :value="mainTeachers"> </PeoplePanel>
        </v-card-text>
      </v-card>
      <v-card>
        <v-system-bar>weitere Lehrpersonen</v-system-bar>
        <v-card-text>
          <PeoplePanel :value="additionalTeachers"> </PeoplePanel>
        </v-card-text>
      </v-card>
    </template>
    <template v-if="view == 'list'">
      <v-card class="mb-4">
        <v-system-bar>Klassenteam (Liste)</v-system-bar>
        <v-list>
          <PersonItem
            v-for="teacher in mainTeachers"
            :key="teacher.id"
            :value="teacher"
          />
        </v-list>
      </v-card>
      <v-card>
        <v-system-bar>weitere Lehrpersonen</v-system-bar>
        <v-list>
          <PersonItem
            v-for="teacher in additionalTeachers"
            :key="teacher.id"
            :value="teacher"
          />
        </v-list>
      </v-card>
    </template>
  </div>
</template>

<script>
import EmailChip from "common/components/EmailChip.vue";
import PersonItem from "@/components/PersonItem.vue";
import PeoplePanel from "common/components/PeoplePanel.vue";

export default {
  name: "SchoolClassTeam",
  components: { EmailChip, PersonItem, PeoplePanel },
  props: ["schoolClass"],
  data() {
    return {
      team: [],
      view: "panel",
    };
  },
  watch: {
    schoolClass() {
      this.fetchData();
    },
  },
  computed: {
    teacherDL() {
      return this.schoolClass.code
        ? `lehrpersonen_${this.schoolClass.code.toLowerCase()}@gymkirchenfeld.ch`
        : "";
    },
    additionalTeachers() {
      return this.team.filter((teacher) => !teacher.mainTeacher);
    },
    mainTeachers() {
      return this.team.filter((teacher) => teacher.mainTeacher);
    },
  },
  methods: {
    async fetchData() {
      if (!this.schoolClass.id) return;
      this.team = await this.apiGet({
        resource: "schoolclass/team",
        id: this.schoolClass.id,
      });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
