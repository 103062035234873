<template>
  <div class="d-flex justify-center flex-wrap">
    <v-card outlined v-for="item in value" :key="`card${item.id}`" width="200">
      <PortraitImage large :value="item" />
      <v-card-text class="text-center">
        <strong><PersonName :value="item" flags /></strong>
        <div v-if="item.description">
          {{ item.description }}
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import PersonName from "common/components/PersonName";
import PortraitImage from "common/components/PortraitImage.vue";
export default {
  name: "PeoplePanel",
  components: { PersonName, PortraitImage },
  props: {
    value: [],
  },
};
</script>
<style scoped>
.d-flex {
  gap: 1rem;
}
</style>
